





























































































































































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Form} from '@/types';
import {User} from '@/models/User';
import {Company} from '@/models/Company';
import {AppInfo} from '@/models/AppInfo';
import {ElInput} from '@/types/input';
import {AccessRequest} from '@/models/AccessRequest';
import {Orders} from '@/models/Orders';
import {StatusAccessRequest, StatusAccessRequestLookup} from '@/enums/StatusAccessRequest';
import Inputmask from '@/plugins/vueinputmask';
import {Validator} from '@/utils/Validator';
import SuccessMessage from '@/components/commons/SuccessMessage.vue';
import WarningMessage from '@/components/commons/WarningMessage.vue';
import ErrorMessage from '@/components/commons/ErrorMessage.vue';
import Recaptcha from '@/components/register/Recaptcha.vue';

const AuthStore = namespace('AuthStore');
const AppInfoStore = namespace('AppInfoStore');

@Component({
    name: 'CustomerAccessRequest',
    components: {Recaptcha, SuccessMessage, WarningMessage, ErrorMessage},
})
export default class CustomerAccessRequest extends Vue {

    public $refs!: {
        form: Form,
        table: any,
        recaptcha: Recaptcha,
    };

    @AppInfoStore.State
    public info!: AppInfo;

    @AuthStore.State
    public user!: User;

    @AuthStore.State
    public currentCompany!: Company;

    @AuthStore.State
    public successMessage!: string;

    @AuthStore.State
    public warningMessage!: string;

    @AuthStore.State
    public errorMessage!: string;

    @AuthStore.Mutation
    public setSuccessMessage!: (newSuccessMessage: string) => Promise<void>;

    @AuthStore.Mutation
    public setWarningMessage!: (newWarningMessage: string) => Promise<void>;

    @AuthStore.Mutation
    public setErrorMessage!: (newErrorMessage: string) => Promise<void>;

    public submitParams: any = {};
    public idAttr: string = '';
    public statusAttr: string = '';

    public currentCpfCnpj: string = '';
    public empresaLoginCpfCnpj: string = '';
    public empresaLoginIdTransportadora: string = '';

    public allRequestList: AccessRequest[] = [];
    public value: string = '';
    public url: string = '';

    /*
    * Carrega item para a tabela
    */
    public isLoading: boolean = false;

    /*
     * Para ocultar ou mostra colunas de cnpj e id da transportadora usadas no PROD
     */
    public isDedicadedVersion: boolean = false;

    /*
    * Quantidade de registros
    */
    public totalRecords: number = 0;

    // drawer
    public dialog: boolean = false;

    /*
     * Altura default da tabela da listagem
     */
    public tableHeight: string = '300px';

    // Modal de adição de requisição de acesso
    public dialogVisible: boolean = false;

    // Desabilita botão para impedir de realizar múltiplas requisições
    public disableAdd: boolean = false;

    // Variavel auxiliar para timeout
    public activeTimeout: any;

    // Utilizado para bloquear os campos de busca de transportadora
    public busca: string = 'CNPJ';

    /**
     * Ordenacao padrao
     */
    public sortDefault: object = {
        field: 'dataSolicitacao',
        type: 'asc',
    };

    /*
    * Parâmetros a serem distribuidos para controle de páginação
    * e exibição de linhas da tabela
    */
    public serverParams: any = {
        columnFilters: {},
        sort: this.sortDefault,
        page: 1,
        perPage: 50,
    };

    /*
    * propriedades das colunas da tabela
    */
    public columns = [
        {
            label: 'CPF / CNPJ Solicitado',
            field: 'cpfCnpj',
            type: 'string',
            sortable: true,
            tdClass: 'text-left',
            thClass: 'text-left',
        },
        {
            label: 'CNPJ Transportadora',
            field: 'cnpjTransportadora',
            type: 'string',
            sortable: false,
            tdClass: 'text-left',
            thClass: 'text-left',
            width: '0px',
        },
        {
            label: 'ID Transportadora',
            field: 'idTransportadora',
            type: 'string',
            sortable: false,
            tdClass: 'text-left',
            thClass: 'text-left',
            width: '0px',
        },
        {
            label: 'Data Solicitação',
            field: 'dataSolicitacao',
            type: 'date',
            tdClass: 'text-center',
            thClass: 'text-center',
            dateInputFormat: `yyyy-MM-dd\'T\'HH:mm:ss.SSS-0300`,
            dateOutputFormat: 'dd/MM/yyyy HH:mm',
            sortable: true,
        },
        {
            label: 'Data da Resposta',
            field: 'dataAutorizacao',
            type: 'date',
            tdClass: 'text-center',
            thClass: 'text-center',
            dateInputFormat: `yyyy-MM-dd\'T\'HH:mm:ss.SSS-0300`,
            dateOutputFormat: 'dd/MM/yyyy HH:mm',
            sortable: true,
        },
        {
            label: 'Situação',
            field: 'status',
            type: 'string',
            tdClass: 'text-center',
            thClass: 'text-center',
            sortable: true,
        },
    ];

    /*
    * Linhas da tebela
    */
    public rows: Orders[] = [] as Orders[];

    public setColumnFilters(params): void {
        this.serverParams.columnFilters = params;
    }

    /*
    * dispara a busca e fecha formulário
    */
    public search(): void {
        this.onPageChange('1');
    }

    /*
    * atualiza os parâmetros de paginação
    */
    public updateParams(newProps): void {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
    }

    /*
    * carrega as página com o snovos parâmetros
    */
    public onPageChange(params): void {
        this.updateParams({page: params.currentPage});
        this.loadItems();
    }

    /*
    * parâmetros por página a partir da página atual
    */
    public onPerPageChange(params): void {
        this.updateParams({perPage: params.currentPerPage});
        this.loadItems();
    }

    /*
    * recarrega a página
    */
    public onRefreshClick(): void {
        this.serverParams = {
            columnFilters: {},
            page: 1,
            perPage: 50,
            sort: this.sortDefault,
        };
        this.$refs.table.$refs.paginationBottom.currentPage = 1;
        this.$refs.table.reset();
    }

    /*
    * distribui de forma descrescente os itens da colunas da página
    */
    public onSortChange(params): void {
        if (params.length) {
            this.updateParams({
                sort: {
                    field: params[0].field,
                    type: params[0].type,
                },
            });
        }
        this.loadItems();
    }

    /*
     * Se a aplicacao ta configurada como dedicada
     */
    public isDedicaded(): boolean {
        return this.info != null && this.info.dedicated;
    }

    /*
    * Carrega a página a partir dos dados coletados pelo ponto de acesso
    */
    public loadItems(): void {
        if (this.currentCompany.id != null && this.info.dedicated) {
            this.url = `/acesso/list/${this.user.login}/${this.currentCompany.id}`;
        } else {
            this.url = `/acesso/list/${this.user.login}/`;
        }

        this.$axios
            .post(
                this.url,
                this.serverParams.columnFilters,
                {
                    params: Object.assign({}, this.serverParams),
                },
            )
            .then((response) => {
                const data = response.data || {};
                this.totalRecords = data.recordsFiltered;
                this.rows = data.resources || [];
            });
    }

    public maskedCpfCnpj(value: string): string {
        if (value.length === 11) {
            return Inputmask.format(value, {mask: '###.###.###-##'});
        }
        if (value.length === 14) {
            return Inputmask.format(value, {mask: '##.###.###/####-##'});
        }
        return 'CPF ou CNPJ inválido: ' + value;
    }

    public statusRender(value: string): StatusAccessRequest {
        return StatusAccessRequestLookup.getStatusByKey(value);
    }

    public async verificaExistenciaDados(cnpj: string, id: string): Promise<boolean> {
        if (this.busca === 'CNPJ') {
            id = cnpj;
        }
        this.showSuccess('Verificando transportadora...');
        const resp: any = await this.$axios.get('/acesso/findempresa', {params: {id}})
            .catch((error) => {
                this.showError('Erro tentar buscar transportadora: ' + error);
                return false;
            });

        return resp.data;
    }

    /**
     * Envia um cpfCnpj para a lista de solicitações
     * @param params
     */
    public submit(params, id) {
        if (this.isDedicaded()) {
            this.submitParams = [params.cpfCnpj];
        } else {
            this.submitParams = params;
            if (this.busca === 'CNPJ') {
                this.submitParams.idTransp = '';
            } else {
                this.submitParams.cnpjTransp = '';
            }
        }
        this.idAttr = id ? `?id=${id}` : '';
        this.applyFilters().then(() => {
            this.disableAdd = false;
        });
    }

    public async applyFilters() {
        this.showSuccess('Processando requisição...');

        let defaultUrl = '';

        // quando houver empresa login setada
        if (this.currentCompany.id != null && this.info.dedicated) {
            defaultUrl = `/acesso/${this.user.login}/${this.currentCompany.id}${this.idAttr}`;
        } else {
            defaultUrl = `/acesso/${this.user.login}/`;
        }

        this.$axios.post(defaultUrl, this.submitParams)
            .then((response) => {
                if (response.status === 201) {
                    this.$toast.success(`Sua solicitação de acesso foi enviada para o
                                         Administrador. Aguarde a análise do seu pedido.`);
                    this.cleanAndCloseModal();
                    this.loadItems();
                } else if (response.status === 200 && response.data.length) {
                    this.showWarning(response.data);
                }
            })
            .catch((error) => {
                this.showError('Erro inesperado ao tentar criar a requisição de acesso: ' + error);
            });
    }

    /**
     * Validacao de CPF ou CNPJ
     */
    public isValidCpfCnpj(param: string, type: number): boolean {
        if (this.busca === 'ID' && type === 2) {
            return true;
        }
        if (param.length === 14 || type === 2) { // É cnpj
            if (!Validator.isValidCnpj(param)) {// É inválido
                if (type === 1) {// Ver. se é cliente ou transportadora para msg
                    this.showWarning('Informe um CPF ou CNPJ válido para o cliente!');
                } else if (type === 2) {
                    this.showWarning('Informe um CNPJ válido para a transportadora!');
                }
                return false;
            }
        } else if (!Validator.isValidCpf(param)) {// É cpf inválido
            this.showWarning('Informe um CPF ou CNPJ válido para o cliente!');
            return false;
        }
        return true;
    }

    /**
     * Trata a validacao do cpfCnpj que será enviado para lista de solicitação
     * @param event
     */
    public async addRowSolicitacaoAcesso(event: Event | null): Promise<void> {

        const rows = this.$refs.table.rows;

        this.allRequestList = rows.map((row) => {
            return {
                cpfCnpj: row.cpfCnpj,
                cnpjTransportadora: row.cnpjTransportadora,
                idTransportadora: row.idTransportadora,
            };
        });

        const cpfCnpjCliente = this.currentCpfCnpj.replace(/\D/g, '');
        const params: any = {
            cpfCnpj: cpfCnpjCliente,
        };

        if (this.isDedicaded() && !cpfCnpjCliente.length) {
            this.showWarning(`Preencha Seu CPF ou CNPJ!`);
            return;
        }

        if (!this.isValidCpfCnpj(cpfCnpjCliente, 1)) {
            return;
        }

        if (this.isDedicaded()) {
            if (this.allRequestList.some((ar) => ar.cpfCnpj === cpfCnpjCliente)) {
                this.showWarning('CPF/CNPJ já solicitado! ' +
                    'Aguarde a análise caso o acesso ainda não esteja liberado.');
                return;
            }
        }

        if (!this.$refs.recaptcha.isChecked) {
            this.showWarning('Preencha o reCAPTCHA para realizar a solicitação!');
            return;
        }

        if (!this.isDedicaded()) {

            const cnpjTransportadora = this.empresaLoginCpfCnpj.replace(/\D/g, '');
            const idTransportadora = this.empresaLoginIdTransportadora;

            Object.assign(params, {
                cnpjTransp: cnpjTransportadora,
                idTransp: idTransportadora,
            });

            if (this.busca === 'ID' && !idTransportadora.length) {
                this.showWarning(`Preencha o ID da transportadora!`);
                return;
            }

            if (this.busca === 'CNPJ' && !cnpjTransportadora.length) {
                this.showWarning(`Preencha o CNPJ da transportadora!`);
                return;
            }

            if (!this.isValidCpfCnpj(cnpjTransportadora, 2)) {
                return;
            }

            if (cnpjTransportadora === cpfCnpjCliente && this.busca === 'CNPJ') {
                this.showWarning(`CNPJ da transportadora não pode ser igual ` +
                    `ao do requisitante!`);
                return;
            }

            if (this.allRequestList.some((ar) => ar.cpfCnpj === cpfCnpjCliente
                && (
                    (cnpjTransportadora !== '' && ar.cnpjTransportadora === cnpjTransportadora)
                    || (idTransportadora !== '' && ar.idTransportadora === idTransportadora)
                ))) {
                this.showWarning('Este CPF/CNPJ já foi solicitado para a mesma Transportadora! ' +
                    'Aguarde a análise da sua solicitação caso o acesso ainda não esteja liberado.');
                return;
            }

            this.submit(params, null);
        } else {
            this.disableAdd = true;
            this.submit(params, null);
        }
    }

    public cleanAndCloseModal(): void {
        this.busca = 'CNPJ';
        this.dialogVisible = false;
        this.setSuccessMessage('');
        this.setErrorMessage('');
        this.setWarningMessage('');
        this.currentCpfCnpj = '';
        this.empresaLoginCpfCnpj = '';
        this.empresaLoginIdTransportadora = '';
    }

    public showSuccess(msg: string): void {
        this.setSuccessMessage(msg);
        this.setWarningMessage('');
        this.setErrorMessage('');
    }

    public showError(msg: string): void {
        this.setSuccessMessage('');
        this.setWarningMessage('');
        this.setErrorMessage(msg);
    }

    public showWarning(msg: string): void {
        clearTimeout(this.activeTimeout);
        this.setSuccessMessage('');
        this.setWarningMessage(msg);
        this.setErrorMessage('');
        this.activeTimeout = setTimeout(() => this.setWarningMessage(''), 6000);
    }

    public showRenewBtn(status: string, date: string): boolean {
        if (status === 'Aguardando') {
            if (this.diffDays(date) >= this.info.expirationAccessRequest) {
                return true;
            }
        }
        return false;
    }

    public diffDays(date: string): number {
        const now = this.$moment(new Date()); // Data de hoje
        const past = this.$moment(new Date(date)); // Outra data no passado
        const duration = this.$moment.duration(now.diff(past));

        // Mostra a diferença em dias
        return Math.floor(duration.asDays());
    }

    public getDateHour(value: string): string {
        return value ? this.$moment(value).format('DD/MM/YYYY HH:mm') : ' - ';
    }

    public getAutorizador(value: string): string {
        return value ? value : ' - ';
    }

    public calcPositionTable(): void {
        if (this.$refs.table != null) {
            const tableHeader = document.getElementsByClassName('vgt-fixed-header');
            const tableFooter = document.getElementsByClassName('vgt-wrap__footer');
            const headerPos = tableHeader[0].getBoundingClientRect();
            const footerPos = tableFooter[0].getBoundingClientRect();
            const calc = footerPos.top - (headerPos.bottom - headerPos.height);
            this.tableHeight = calc.toFixed(0);
        }
    }

    public getFixedHeigthTable(): string {
        return `${this.tableHeight}px`;
    }

    /*
     * monta a página
     */
    public mounted(): void {
        this.$nextTick(() => {
            this.calcPositionTable();
            this.isDedicadedVersion = !this.isDedicaded();

            // se for uma versao dedicada oculta colunas ref a versao do portal no PROD.
            if (this.isDedicadedVersion) {

                const columns = this.$refs.table.columns;
                columns.forEach((column) => {
                    if (column.field === 'cnpjTransportadora') {
                        column.width = 'auto';
                        column.sortable = true;
                    }
                    if (column.field === 'idTransportadora') {
                        column.width = 'auto';
                        column.sortable = true;
                    }
                });
            }
        });

        window.addEventListener('resize', this.calcPositionTable);
    }

    private unmounted() {
        window.removeEventListener('resize', this.calcPositionTable);
    }
}
