export class Validator {
  public static isValidCpf(value: string): boolean {
    const RADIX = 10;
    const CPF_LENGTH = 11;
    let sum = 0;
    value = value.replace(/\D/g, '');
    if (value.length !== CPF_LENGTH) {
      return false;
    }
    for (let i = 1; i <= 9; i++) {
      sum = sum + parseInt(value.substring(i - 1, i), RADIX) * (CPF_LENGTH - i);
    }
    let diff = (sum * 10) % CPF_LENGTH;
    if ((diff === 10) || (diff === CPF_LENGTH)) {
      diff = 0;
    }
    if (diff !== parseInt(value.substring(9, 10), RADIX)) {
      return false;
    }
    sum = 0;
    for (let k = 1; k <= 10; k++) {
      sum = sum + parseInt(value.substring(k - 1, k), RADIX) * (12 - k);
    }
    diff = (sum * 10) % CPF_LENGTH;
    if ((diff === 10) || (diff === CPF_LENGTH)) {
      diff = 0;
    }
    return diff === parseInt(value.substring(10, CPF_LENGTH), RADIX);
  }

  public static isValidCnpj(cnpj: string): boolean {
    cnpj = cnpj.replace(/[^\d]+/g, '');
    // verificando se tem a quantidade certa de caracter e se não tem todos caracteres iguais
    if (cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj)) {
      return false;
    }
    const t = (cnpj.length - 2);
    const d = cnpj.substring(t);
    const d1 = parseInt(d.charAt(0), 0);
    const d2 = parseInt(d.charAt(1), 0);

    let n;
    let y;
    let s;
    let r;
    const calc = (x) => {
      n = cnpj.substring(0, x),
          y = x - 7,
          s = 0,
          r = 0;
      for (let i = x; i >= 1; i--) {
        // @ts-ignore
        s += n.charAt(x - i) * y--;
        if (y < 2) {
          y = 9;
        }
      }
      r = 11 - s % 11;
      return r > 9 ? 0 : r;
    };
    return calc(t) === d1 && calc(t + 1) === d2;
  }
}
