























































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import CustomerAccessRequest from '@/components/orders/CustomerAccessRequest.vue';
import {StatusAccessRequest, StatusAccessRequestLookup} from '@/enums/StatusAccessRequest';
import FilterBy from '@/components/commons/FilterBy.vue';
import FilterByItem from '@/components/commons/FilterByItem.vue';
import Filters from '@/utils/Filters';

const AuthStore = namespace('AuthStore');
Vue.use(Filters);

export class FormFilters {
    public show: boolean = false;
    public cpfCnpj: string = '';
    public status: string = '';
}

@Component({
    name: 'Orders',
    components: {CustomerAccessRequest, FilterBy, FilterByItem},
})
export default class Orders extends Vue {

    public $refs!: {
        customer: any,
        drawer: any,
    };

    @AuthStore.Mutation
    public setSuccessMessage!: (newSuccessMessage: string) => Promise<void>;

    @AuthStore.Mutation
    public setWarningMessage!: (newSuccessMessage: string) => Promise<void>;

    @AuthStore.Mutation
    public setErrorMessage!: (newErrorMessage: string) => Promise<void>;

    @AuthStore.State
    public currentStatusFilter!: StatusAccessRequest;

    @AuthStore.Mutation
    public setCurrentStatusFilter!: (newStatusFilter: StatusAccessRequest | undefined) => Promise<void>;

    /*
    * largura do label
    */
    public formLabelWidth: any = '120px';

    // drawer
    public dialog: boolean = false;

    /*
     * propriedade pra filtro de busca
     */
    public formFilters: FormFilters = new FormFilters();

    public openFormPesq(): void {
    }

    get statusFiltros(): StatusAccessRequest[] {
        return StatusAccessRequestLookup.lookup;
    }

    /*
    * dispara a busca e fecha formulário
    */
    public search(): void {
        this.applyFilters();
        this.formFilters.show = true;
        this.$refs.drawer.closeDrawer();
        this.$refs.customer.onPageChange('1');
    }

    public onRefreshClick(): void {
        this.formFilters = new FormFilters();
        this.$refs.customer.currentCpfCnpj = '';
        this.formFilters.show = true;
        this.$refs.drawer.closeDrawer();
        this.clean();
        this.$refs.customer.onRefreshClick();
    }

    /*
    * cancela o formulário de busca
    */
    public cancelForm(): void {
        this.dialog = false;
    }

    public getStatusAccessFilter(param): string {
        return StatusAccessRequestLookup.lookup[param].toString();
    }

    /*
    * aplica os filtros de busca por item da página
    */
    public applyFilters() {
        this.$refs.customer.setColumnFilters(
            {
                cpfCnpj: this.formFilters.cpfCnpj ? this.formFilters.cpfCnpj.replace(/\D/g, '') : null,
                status: !Vue.prototype.$isEmpty(this.formFilters.status) ?
                    this.getStatusAccessFilter(this.formFilters.status) : null,
            },
        );
    }

    /**
     * Reset filtros de pesquisa
     */
    public cleanAll(): void {
        this.formFilters.show = false;
        this.onRefreshClick();
    }

    public clean(): void {
        this.setSuccessMessage('');
        this.setWarningMessage('');
        this.setErrorMessage('');
    }

    public created(): void {
        this.clean();
    }
}
